import _ from "lodash";
import { ActionTypes } from "../actions/actionTypes";
import { IUserInfo } from "../../models/applicationState";
import { AnyAction } from "../actions/actionCreators";
// tslint:disable-next-line:no-var-requires
const tagColors = require("../../react/components/common/tagColors.json");

/**
 * Reducer for userInfo. Actions handled:
 * LOAD_USERINFO_SUCCESS

 * @param state - Current project
 * @param action - Action that was dispatched
 */
export const reducer = (state: IUserInfo = null, action: AnyAction): IUserInfo => {
    switch (action.type) {
        case ActionTypes.LOAD_USERINFO_SUCCESS:
            return { ...action.payload };
        default:
            return state;
    }
};
