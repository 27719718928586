import _ from "lodash";
import shortid from "shortid";
import axios from "axios";
import { StorageProviderFactory } from "../providers/storage/storageProviderFactory";
import {
    IUserInfo, IProject, UserRole, ISecurityToken, AppError,
    ErrorCode, ModelPathType, IActiveLearningSettings, ITag,
} from "../models/applicationState";
import Guard from "../common/guard";
import { constants } from "../common/constants";
import { createQueryString } from "../common/utils";
import { ExportProviderFactory } from "../providers/export/exportProviderFactory";
import { decryptProject, encryptProject } from "../common/utils";
import packageJson from "../../package.json";
import { ExportAssetState } from "../providers/export/exportProvider";
import { IExportFormat } from "vott-react";
import { appInfo } from "../common/appInfo";
import { OpenWorksStorage } from "../providers/storage/openWorksStorage";
const tagColors = require("../react/components/common/tagColors.json");
/**
 * Functions required for a project service
 * @member save - Save a project
 * @member delete - Delete a project
 */
export interface IProjectService {
    load(project: IProject, securityToken: ISecurityToken): Promise<IProject>;
    save(project: IProject, securityToken: ISecurityToken): Promise<IProject>;
    delete(project: IProject): Promise<void>;
    isDuplicate(project: IProject, projectList: IProject[]): boolean;
}

const defaultActiveLearningSettings: IActiveLearningSettings = {
    autoDetect: false,
    predictTag: true,
    modelPathType: ModelPathType.Coco,
};

const defaultExportOptions: IExportFormat = {
    providerType: "vottJson",
    providerOptions: {
        assetState: ExportAssetState.Visited,
        includeImages: true,
    },
};

/**
 * @name - Project Service
 * @description - Functions for dealing with projects
 */
export default class ProjectService implements IProjectService {
    public async loadUserInfo(): Promise<IUserInfo> {
        try {
            const url = `${OpenWorksStorage.GET_USERINFO}`;
            const response = await axios.get(url, { withCredentials: true });
            console.log('yhcho projectService loadUserInfo...', response);

            const loadedUserInfo: IUserInfo =
            {
                id: response.data ? response.data.user.id : "",
                name: response.data ? response.data.user.name : "",
                userRole: response.data ? response.data.user.role : UserRole.InvalidUser
            };
            return Promise.resolve({ ...loadedUserInfo });
        } catch (e) {
            console.log('yhcho projectService loadUserInfo error',e);
            const error = new AppError(ErrorCode.InvalidUser, "Error load UserInfo");
            return Promise.reject(error);
        }
    }

    public async loadDB(projectId: String): Promise<IProject> {
        Guard.null(projectId);

        try {
            const query = {
                projectId: projectId
            };

            const url = `${OpenWorksStorage.LOADPROJECT_URL}?${createQueryString(query)}`;
            const response = await axios.get(url);
            console.log('yhcho projectService loadDB response... ',response);
            // this.ensureBackwardsCompatibility(loadedProject);
            const itags: ITag[] = [];
            if(response.data.tags.indexOf('Unmatched') < 0){
                response.data.tags.unshift('Unmatched');
            }
            for(let i=0;i<response.data.tags.length;i++){
                if( typeof response.data.tags[i] === 'string'){
                    const newTag: ITag = {
                        name: response.data.tags[i],
                        color: tagColors[i]
                    };
                    itags.push(newTag);
                }
            }
            const loadedProject: IProject =
            {
                id: projectId.toString(),
                name: response.data.name,
                subtitle: response.data.subtitle,
                version: appInfo.version,
                securityToken: null,
                description: response.data.description,
                status: response.data.status,
                assets: {},
                exportFormat: defaultExportOptions,
                sourceConnection: null,
                targetConnection: null,
                tags: itags,
                videoSettings: null,
                activeLearningSettings: defaultActiveLearningSettings,
                autoSave: true
            };
            return Promise.resolve({ ...loadedProject });
        } catch (e) {
            console.log('yhcho projectService loadDB error',e);
            const error = new AppError(ErrorCode.ProjectInvalidSecurityToken, "Error load project db");
            return Promise.reject(error);
        }
    }

    /**
     * Loads a project
     * @param project The project JSON to load
     * @param securityToken The security token used to decrypt sensitive project settings
     */
    public load(project: IProject, securityToken: ISecurityToken): Promise<IProject> {
        Guard.null(project);

        try {
            // const loadedProject = decryptProject(project, securityToken);
            const loadedProject = project;
            // Ensure tags is always initialized to an array
            if (!loadedProject.tags) {
                loadedProject.tags = [];
            }

            // Initialize active learning settings if they don't exist
            if (!loadedProject.activeLearningSettings) {
                loadedProject.activeLearningSettings = defaultActiveLearningSettings;
            }

            // Initialize export settings if they don't exist
            if (!loadedProject.exportFormat) {
                loadedProject.exportFormat = defaultExportOptions;
            }

            this.ensureBackwardsCompatibility(loadedProject);

            return Promise.resolve({ ...loadedProject });
        } catch (e) {
            const error = new AppError(ErrorCode.ProjectInvalidSecurityToken, "Error decrypting project settings");
            return Promise.reject(error);
        }
    }

    /**
     * Save a project
     * @param project - Project to save
     * @param securityToken - Security Token to encrypt
     */
    public async save(project: IProject, securityToken: ISecurityToken): Promise<IProject> {
        Guard.null(project);

        if (!project.id) {
            project.id = shortid.generate();
        }

        // Ensure tags is always initialized to an array
        if (!project.tags) {
            project.tags = [];
        }
 
        // Initialize active learning settings if they don't exist
        if (!project.activeLearningSettings) {
            project.activeLearningSettings = defaultActiveLearningSettings;
        }

        // Initialize export settings if they don't exist
        if (!project.exportFormat) {
            project.exportFormat = defaultExportOptions;
        }

        project.version = packageJson.version;
        console.log('yhcho projectService save.. targetConnection ',project.targetConnection);
        // const storageProvider = StorageProviderFactory.createFromConnection(project.targetConnection);
        await this.saveExportSettings(project);
        // project = encryptProject(project, securityToken);

        // await storageProvider.writeText(
        //     `${project.name}${constants.projectFileExtension}`,
        //     JSON.stringify(project, null, 4),
        // );

        // const response = await axios.get(url);
        // console.log('yhcho projectService save response...',response);

        const projectParam = JSON.parse(JSON.stringify(project));
        const strTags = [];
        for(let i=0; i< projectParam.tags.length; i++){
            if(typeof projectParam.tags[i] === "object"){
                strTags.push(projectParam.tags[i].name);
            }else{
                strTags.push(projectParam.tags[i]);
            }
        }
        projectParam.tags = strTags;
        axios.post(OpenWorksStorage.CREATEPROJECT_URL, {
            data: JSON.stringify(projectParam, null, 4),
          })
          .then(function (response) {
            console.log(response);
            console.log('yhcho projectService save response...',response);
          })
          .catch(function (error) {
            console.log('yhcho projectService save response...error ',error);
          });

        return project;
    }

    /**
     * Delete a project
     * @param project - Project to delete
     */
    public async delete(project: IProject): Promise<void> {
        Guard.null(project);

        const storageProvider = StorageProviderFactory.createFromConnection(project.targetConnection);

        // Delete all asset metadata files created for project
        const deleteFiles = _.values(project.assets)
            .map((asset) => storageProvider.deleteFile(`${asset.id}${constants.assetMetadataFileExtension}`));

        await Promise.all(deleteFiles);
        await storageProvider.deleteFile(`${project.name}${constants.projectFileExtension}`);
    }

    /**
     * Checks whether or not the project would cause a duplicate at the target connection
     * @param project The project to validate
     * @param projectList The list of known projects
     */
    public isDuplicate(project: IProject, projectList: IProject[]): boolean {
        const duplicateProjects = projectList.find((p) =>
            p.id !== project.id &&
            p.name === project.name &&
            JSON.stringify(p.targetConnection.providerOptions) ===
            JSON.stringify(project.targetConnection.providerOptions),
        );
        return (duplicateProjects !== undefined);
    }

    private async saveExportSettings(project: IProject): Promise<void> {
        if (!project.exportFormat || !project.exportFormat.providerType) {
            return Promise.resolve();
        }

        const exportProvider = ExportProviderFactory.createFromProject(project);

        if (!exportProvider.save) {
            return Promise.resolve();
        }

        project.exportFormat.providerOptions = await exportProvider.save(project.exportFormat);
    }

    /**
     * Ensures backwards compatibility with project
     * @param project The project to update
     */
    private ensureBackwardsCompatibility(project: IProject) {
        const projectVersion = project.version.toLowerCase();

        if (projectVersion.startsWith("2.0.0")) {
            // Required for backwards compatibility with v2.0.0 release
            if (project.exportFormat.providerType === "tensorFlowPascalVOC") {
                project.exportFormat.providerType = "pascalVOC";
            }
        }
    }
}
